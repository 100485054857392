import { Component, OnInit, OnDestroy, Injector, Input, ViewChildren, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import * as _moment from 'moment';
import { Moment } from 'moment';

// components
import { DxPopupComponent } from 'devextreme-angular';
import { ItemDetailsComponent } from '../../shared/components/item-details/item-details.component';
import { TranslatableFieldComponent } from '../../shared/components/translatable-field/translatable-field.component';

// models
import { ProductPropertyTypeEnum } from '../../shared/enum/productPropertyTypeEnum';
import { UrlTypeEnum } from '../../shared/enum/urlTypeEnum';
import { Language } from '../../shared/models/language';
import { MasterData, MasterDataRow, MasterDataListField, MasterDataValue } from '../../shared/models/master-data';
import { ProductPropertyType } from '../../shared/models/product';

// services
import { LanguageService } from '../../shared/services/language.service';

import { MasterDataService } from '../shared/services/master-data.service';

const moment = (_moment as any).default ? (_moment as any).default : _moment;

@Component({
  selector: 'master-data-list-row-editor-component',
  templateUrl: 'master-data-list-row-editor.component.html',
  styleUrls: ['./master-data-list-row-editor.component.scss']
})
export class MasterDataListRowEditorComponent extends ItemDetailsComponent<MasterDataRow> implements OnInit, OnDestroy {
  translations: any;
  auctionClusterId: number;
  lookupTableRowId: number;
  languages: Array<Language> = [];
  productPropertyTypes: Array<ProductPropertyType> = [];
  masterDataLists: Array<MasterData> = [];
  masterDataListFields: Array<MasterDataListField> = [];
  masterData = new MasterData();
  emptyTranslation = {};

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  @ViewChild(DxPopupComponent) popup: DxPopupComponent;

  @Input('masterDataListFields')
  set fields(value: Array<MasterDataListField>) {
    this.masterDataListFields = value;
    if (this.masterDataListFields) {
      this.initializeNewModel();
    }
  }

  @Input('languages')
  set setLanguages(value: Array<Language>) {
    this.languages = value;
    if (this.languages) {
      this.initializeEmptyJsonForTranslatable();
    }
  }

  @ViewChildren(TranslatableFieldComponent) translatableFields: Array<TranslatableFieldComponent> = [];

  constructor(
    protected injector: Injector,
    private languageService: LanguageService,
    private route: ActivatedRoute,
    private dataService: MasterDataService
  ) {
    super(injector);
    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  ngOnInit() {
    this.auctionClusterId = +this.route.snapshot.params['id'];
    this.setTranslations('MASTER_DATA');
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  initializeNewModel() {
    this.model = new MasterDataRow();
    if (this.masterDataListFields) {
      this.masterDataListFields.forEach(f => {
        const newMasterDataValue = new MasterDataValue();
        newMasterDataValue.masterDataListFieldId = f.masterDataListFieldId;
        if (f.translatable) {
          newMasterDataValue.stringValue = JSON.stringify(this.emptyTranslation);
        }
        if (f.propertyTypeId === ProductPropertyTypeEnum.URL && f.propertyTypeFormatId === UrlTypeEnum.IMAGE_URL) {
          newMasterDataValue.displayUrlImageValue = '../../../assets/images/image_placeholder.jpg';
          newMasterDataValue.displayUrlTextValue = null;
        }
        this.model.values.push(newMasterDataValue);
      });
    }
  }

  initializeEmptyJsonForTranslatable() {
    this.languages.forEach(lang => {
      this.emptyTranslation[lang.code] = '';
    });
  }

  async open(masterData: MasterData, masterDataRowId: number, languages: Array<Language>) {
    this.languages = languages;
    this.masterData = JSON.parse(JSON.stringify(masterData));
    if (masterDataRowId) {
      let row = null;
      if (masterData.rows == null || masterData.rows.length == 0) {
        row = await this.dataService.getMasterDataRowById(masterDataRowId, this.auctionClusterId).toPromise();
      }
      else {
        row = masterData.rows.find(mdr => mdr.masterDataListRowId === masterDataRowId);
      }
      if (row) {
        this.isEditMode = true;
        this.spinner.show();
        this.model = JSON.parse(JSON.stringify(row));
        this.parseImageFields(this.model);
        this.utcDatetimeValuesToLocal();
        this.isOpened = true;
        this.spinner.hide();
        return;
      }
    }

    // no row info, create new object
    this.initializeEmptyJsonForTranslatable();
    this.initializeNewModel();
    this.isEditMode = false;
    this.isOpened = true;
  }

  getTranslation(value: string) {
    if (value) {
      return this.languageService.getTranslatableText(value);
    }
  }

  parseImageFields(model: MasterDataRow) {
    model.values.forEach(mdv => {
      if (mdv.urlValue) {
        mdv.displayUrlImageValue = mdv.displayUrlTextValue = mdv.urlValue;
      } else {
        mdv.displayUrlTextValue = null;
        mdv.displayUrlImageValue = '../../../assets/images/image_placeholder.jpg';
      }
    });
  }

  createImage(index: number, event: any) {
    const file = event.target.files[0];
    const fr = new FileReader();

    fr.onload = (() => {
      this.model.values[index].imageValue = <string>fr.result;
    });

    fr.readAsDataURL(file);
  }

  setDateTimeValue(index: number, dateTime: Moment) {
    if (dateTime) {
      this.model.values[index].dateTimeValue = moment(dateTime).toDate();
    }
  }

  utcDatetimeValuesToLocal() {
    this.model.values.forEach(value => {
      if (value.dateTimeValue) {
        const serverReturnedTime = moment.utc(value.dateTimeValue);
        value.dateTimeValue = moment(serverReturnedTime).local();
      }
    });
  }

  localDatetimeValuesToUtc() {
    this.model.values.forEach(value => {
      if (value.dateTimeValue) {
            value.dateTimeValue = moment.utc(value.dateTimeValue);
      }
    });
  }

  getTranslatableData() {
    for (let f = 0; f < this.masterDataListFields.length; f++) {
      if (this.masterDataListFields[f].translatable) {
        for (let v = 0; v < this.model.values.length; v++) {
          const field = this.translatableFields.find(tf => tf.componentIndex.toString() === `${v.toString()}`);
          if (field) {
            this.model.values[v].stringValue = field.data;
          }
        }
      }
    }
  }

  isSystemList = () => {
    return (this.masterData.systemListType != 0 
      &&  this.masterData.systemListType != 8/*CoinCodes*/
      &&  this.masterData.systemListType != 14/*MinMaxAmount*/);
  }

  isCoinCode = () => {
    return this.masterData.systemListType === 8/*CoinCodes*/;
  }

  isMinMaxAmounts = () => {
    return this.masterData.systemListType === 14/*MinMaxAmount*/;
  }

  onImageChanged(event: any, index: number) {
    this.spinner.show();
    const file = <File>event.srcElement.files[0];

    if (file) {
      const formData = new FormData();
      formData.append('file', file);

        this.dataService.addMasterDataImage(this.auctionClusterId, formData).subscribe((imgSrc: any) => {

        if (imgSrc.length !== 0) {
          this.spinner.hide();
        }
            this.model.values[index].urlValue = this.model.values[index].displayUrlTextValue = imgSrc;
            this.model.values[index].displayUrlImageValue = imgSrc + '-thumbnail';
      });
    } else {
      this.spinner.hide();
    }
  }

  clearImage(index: number) {
    this.model.values[index].urlValue = null;
    this.model.values[index].displayUrlImageValue = '../../../assets/images/image_placeholder.jpg';
    this.model.values[index].displayUrlTextValue = null;
  }

  save() {
    this.model.masterDataListId = this.masterData.masterDataListId;
    this.localDatetimeValuesToUtc();
    this.getTranslatableData();
    if (this.isEditMode) {
      this.spinner.show();
      this.dataService.editRow(this.auctionClusterId, this.model)
        .subscribe((res: any) => {
          this.initializeNewModel();
          // this.detailsForm.reset();
          this.close(true);
          this.errorMessage = null;
          this.spinner.hide();
        },
          error => {
            this.errorService.show(error);
            this.spinner.hide();
          });
    } else {
      this.spinner.show();
      this.dataService.saveRow(this.auctionClusterId, this.model)
        .subscribe((res: any) => {
          this.initializeNewModel();
          // this.detailsForm.reset();
          this.close(true);
          this.errorMessage = null;
          this.spinner.hide();
        },
          error => {
            this.errorService.show(error);
            this.spinner.hide();
          });
    }
  }
}
