import { LotStatus } from "./transaction-monitor-lot";

export class Catalog {
  catalogId: number;
  auctionId: number;
  auctionClusterId: number;
  productId: number;
  forecastCatalogId: number;
  name: string;
  description: string;
  isForecast: boolean;
  lots: Array<number> = [];
  allowPartialSaleOfGroupedLots: boolean;
  allowPartialSaleOfSingleLotWithMultipleSaleUnits: boolean;
  enableTransactionBuffer: boolean;
  transactionBufferSize: number;
  allowRemoveTransaction: boolean;
  allowPriceAdjust: boolean;
  allowQuantityReduction: boolean;
  groupedBuffer: boolean;
  typeOfGrouping: number;
  groupingBehaviour: number;
  prebid: number;
  prebidPriority: number;
  shortPrebidsEnabled: number;
  shortPrebidPriority: number;
  prebidMinMaxAmounts: number;

  presalesMode: PresalesModeEnum;
  buybackIncluded: BuyBackIncludedEnum;
  includePresalesInCheckLimit: boolean;

  allowLowestStopClockPrice: number;
  partialSaleEntryTimeout: number;
  printTiming: number;
  buyPopupInactivityTimerStart: number;
  buyPopupInactivityTimeout: number;
  deletedTransactionPosition: number;
  allowBuyerChangeByAuctioneer: boolean;
  autoSelectLotsAfterBid: boolean;
  useBoxContentInCalculation: boolean;
  amountIsBoxContentMultitude: boolean;
  autoSelectSingleLot: boolean;
  allowMultiplePrebidsPerBuyerOnSameLot: boolean;
  splitLotToMultipleSubbuyers: number;
  enableCreate: boolean;
  printTicketOnLotCreated: boolean;
  viewOnlyMode: boolean;
  refreshDelay: number;
  allowEditOfTransactionsOutsideBuffer: boolean;
  showMasterDetailInManualGroupsMode: boolean;
  immediateTransactionMode: boolean;
  showCreditLimitFieldsInValidationMode: boolean;
  allowUndoTransaction: boolean;
  allowCopyTransaction: boolean;
  allowMinMaxAmountChangeByAuctioneer: boolean;
  applyMinMaxAmountAfterValidation: boolean;

  isDemoCatalog: boolean;
  demoCatalogId: number;
  isAutoSetStartPrice: boolean;
  autoSetStartPrice: number;
  isAutoStartOnNewLotAfter: boolean;
  autoStartOnNewLotAfter: number;
  isAutoStopClock: boolean;
  autoStopClockAfterSteps: number;
  autoStopBuyerId: number;
  autoValidatePurchase: boolean;
  printTransactions: boolean;
  sendTransactionsToReporting: boolean;
  scheduledCleanup: boolean;
  scheduledCleanupTime: Date;
  cleanupTransactions: boolean;
  cleanupTransactionsDelay: number;
  cleanupSupplyPrebidsMarkings: boolean;
  cleanupSupplyPrebidsMarkingsDelay: number;
  cleanupForecast: boolean;
  cleanupForecastDelay: number;
  cleanupSupplyColorMatrix: boolean;
  cleanupManualGroups: boolean;
  cleanupShoppingList: boolean;
  cleanupPrebidsOnProduct: number;
  demoAuctioneerUserId: number;
  deletedMasterDataLookups: boolean;
  deletedMasterDataLookupsDelay: number;
  enableDelete: boolean;
  allowEditTransactionPrice: boolean;
  allowMarkForConfirmation: boolean;
  showAmount: boolean; 
  showBuyerInfo: boolean;
  enableMultitudeAmount: boolean;
  multitudeAmountRoundingBehavior: number;


  // displayable fields
  auctionName: string;
  productName: string;

  constructor() {
    this.scheduledCleanup = false;
    this.allowPartialSaleOfSingleLotWithMultipleSaleUnits = false;
    this.isDemoCatalog = false;
    this.isAutoStopClock = false;
    this.isAutoStartOnNewLotAfter = false;
    this.isAutoSetStartPrice = false;
    this.enableDelete = false;
    this.splitLotToMultipleSubbuyers = 0;
    this.prebid = 0;
    this.presalesMode = PresalesModeEnum.NONE;
    this.deletedTransactionPosition = 0;
    this.enableTransactionBuffer = true;
    this.typeOfGrouping = 1;
    this.groupingBehaviour = 0;
    this.allowLowestStopClockPrice = 0;
    this.printTiming = 1;
    this.showMasterDetailInManualGroupsMode = true;
    this.immediateTransactionMode = false;
    this.showCreditLimitFieldsInValidationMode = true;
    this.allowUndoTransaction = false;
    this.allowCopyTransaction = false;
    this.applyMinMaxAmountAfterValidation = false;

  }
}

export class CatalogGroupFilterValue {
  catalogGroupFilterValueId: number;
  catalogGroupFilterPropertyId: number;
  masterDataListRowId: number;
  stringValue: string;
}

export class CatalogGroupFilterProperty {
  catalogGroupFilterPropertyId: number;
  catalogGroupFilterId: number;
  productPropertyId: number;
  catalogGroupFilterValue: Array<CatalogGroupFilterValue> = [];
}

export class GroupFilter {
  catalogGroupFilterId: number;
  catalogId: number;
  name: string;
  catalogGroupFilterProperty: Array<CatalogGroupFilterProperty> = [];
}

export class MoveLotProperty {
  productPropertyId: number;
  intValue: number;
  toIntValue: number;
  masterDataListRowIds: Array<number>;
}

export class MoveLot {
  catalogGroupFilterProperty: Array<MoveLotProperty> = [];
}

export class CatalogsRequest {
  permissionIds: Array<number>;
  salesDate: Date;
  isTransaction: boolean
}

export enum AllowLowestStopClockPrice {
  NOTALLOWED = 0,
  ALLOWED = 1
}

export enum DeletedTransactionPositionEnum {
  AFTERCURRENTLOT = 0,
  ENDOFSUPPLIER = 1
}

export enum PrebidEnabled {
  DISABLED = 0,
  ENABLED = 1
}

export enum PrebidOnProductEnum {
  ALL = 1,
  FULLYOBTAINEDAMOUNTS = 2,
  EXPIREDSALESDATE = 4
}

export enum PrebidPriority {
  HIGHERTHANREALTIME = 0,
  LOWERTHANREALTIME = 1,
  EQUALCHANCE = 2
}

export enum PrebidMinMaxAmountsEnum {
  PREBIDS_ARE_IGNORED = 0,
  PREBID_AMOUNT_IS_AUTOMATICALLY_ADJUSTED = 1
}

export enum PresalesModeEnum {
  NONE = 0,
  BUYBACKONLY = 1,
  PRESALESONLY = 2,
  BUYBACKANDPRESALES = 3
}

export enum BuyBackIncludedEnum {
  NOTINCLUDED = 0,
  INCLUDED = 1
}

export enum PrintTimingEnum {
  IMMEDIATELY = 0,
  AFTERBUFFER = 1
}

export enum SplitLotToMultipleSubbuyersEnum {
  ALLOWEDBYAUCTIONEERANDBUYER = 0,
  ALLOWEDBYAUCTIONEERONLY = 1,
  NOTALLOWED = 2
}

export enum TypeOfGrouping {
  NONE = 0,
  AUTOMATIC_GROUPING = 1,
  MANUAL_GROUPING = 2,
  COMBINED_GROUPING = 3
}

export enum ManualGroupingBehaviour {
  SHOW_ALL_GROUPS = 0,
  FILTER_BY_LAST_SELECTED_GROUP = 1
}

export enum PrebidMTOAmountsEnum {
  PREBIDS_ARE_IGNORED = 0,
  PREBID_AMOUNT_IS_AUTOMATICALLY_ADJUSTED = 1
}

export enum MTORoundingBehaviorEnum {
  ROUND_LOWER = 0,
  ROUND_HIGHER = 1
}