import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

// models
import { MinMaxAmountRange } from '../models/minMaxAmountRange';

// services
import { LanguageService } from '../../../shared/services/language.service';


@Component({
  selector: 'minmaxamount-editor-component',
  templateUrl: 'minmaxamount-editor.component.html',
  styleUrls: ['./minmaxamount-editor.component.scss'],
  providers: []
})
export class MinMaxAmountEditorComponent implements OnChanges{
  private _minMaxAmountRanges: MinMaxAmountRange[];

  constructor(private languageService: LanguageService) {
    this.minMaxAmountRanges = []
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.json) {
      this.minMaxAmountRanges = JSON.parse(this.json.replace(/'/gi, '"')); // initial data had json strings with single quotes. Please don't do this.
    }
    else {
      this.minMaxAmountRanges = [];
    }
  }

  @Input('json') json: string;
  @Output() jsonChange = new EventEmitter<string>();

  get minMaxAmountRanges() {
    return this._minMaxAmountRanges;
  }

  set minMaxAmountRanges(value: MinMaxAmountRange[]) {
    this._minMaxAmountRanges = value;
  }

  onDataChanged(e) {
    // devexpress datagrid insists on adding a KEY field if no primary key is designed, which also gets serialized. Annoying.
    var raw = this.minMaxAmountRanges as any[];
    for (let i = 0; i < raw.length; i++) {
      raw[i].__KEY__ = undefined;
    }
    this.json = JSON.stringify(raw);
    this.jsonChange.emit(this.json);
  }
}
