import { Component, OnInit, Injector, ViewChild, ViewChildren, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import * as _moment from 'moment';
import { forkJoin, Subscription } from 'rxjs';
import { DateTimeAdapter, OWL_DATE_TIME_LOCALE, OWL_DATE_TIME_FORMATS } from 'ng-pick-datetime';
import CustomStore from 'devextreme/data/custom_store';
import DataGrid from 'devextreme/ui/data_grid';
import { ActivatedRoute } from '@angular/router';
import { MomentDateTimeAdapter } from 'ng-pick-datetime-moment';

// components
import { DxDataGridComponent } from 'devextreme-angular';
import { FullListComponent } from '../../shared/components/full-list/full-list.component';
import { TranslatableFieldComponent } from '../../shared/components/translatable-field/translatable-field.component';
import { SaveConfirmationComponent } from '../../shared/components/save-confirmation/save-confirmation.component';
import { MasterDataListRowEditorComponent } from './master-data-list-row-editor.component';

// models
import { ProductPropertyTypeEnum } from '../../shared/enum/productPropertyTypeEnum';
import { UrlTypeEnum } from '../../shared/enum/urlTypeEnum';
import { Language } from '../../shared/models/language';
import { MasterData, MasterDataRow, MasterDataListField } from '../../shared/models/master-data';

// services
import { TranslateService } from '@ngx-translate/core';
import { DateTimeService } from '../../shared/services/datetime.service';
import { LanguageService } from '../../shared/services/language.service';

import { CUSTOM_TIME_FORMATS } from '../shared/services/custom-time-formats.service';
import { MasterDataService } from '../shared/services/master-data.service';

const moment = (_moment as any).default ? (_moment as any).default : _moment;

@Component({
  selector: 'master-data-list-editor-component',
  templateUrl: 'master-data-list-editor.component.html',
  styleUrls: ['./master-data-list-editor.component.scss'],
  providers: [{ provide: DateTimeAdapter, useClass: MomentDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE] },
  { provide: OWL_DATE_TIME_FORMATS, useValue: CUSTOM_TIME_FORMATS }]
})
export class MasterDataListEditorComponent extends FullListComponent<MasterDataRow, MasterDataListRowEditorComponent> implements OnInit, OnDestroy {

  @ViewChild('confirmation') confirmation: any;
  @ViewChild('details') detailsComponent: MasterDataListRowEditorComponent;
  @ViewChild('lotForm') lotForm: NgForm;
  @ViewChildren(TranslatableFieldComponent) translatableFields: Array<TranslatableFieldComponent> = [];
  @ViewChild('saveConfirmation') saveConfirmation: SaveConfirmationComponent;
  @ViewChild('dataGrid') dataGrid: any;
  @ViewChild("masterDataGrid") editorGrid: DxDataGridComponent;

  customDataSource: CustomStore;
  auctionClusterId: number;
  masterDataLists: Array<MasterData> = [];
  languages: Array<Language> = [];
  masterDataRowId = 0;
  modelChanged: boolean;
  elements: HTMLCollectionOf<any>;
  emptyTranslation = {};
  deactivateConfirmed = false;
  base64textString: Array<any> = [];
  startDate: any;
  item: MasterData;
  masterDataRows: Array<MasterDataRow> = [];
  masterDataListFields: Array<MasterDataListField> = [];
  total: number;
  loading = true;
  gridState: any;
  firstLoad = true;
  columns: Array<any> = [];
  fetchedAll: boolean = false;
  masterdataListId: number;
  items: any[];
  isAddEnabled: boolean = false;
  popUpShowed: boolean = false;
  popUpOpened: boolean = false;
  imageLoaded: boolean = false;
  largeImageSrc: string = '';

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  dataGridInstance: DataGrid;
  gridColumns = [];

  constructor(
    protected injector: Injector,
    private dataService: MasterDataService,
    private languageService: LanguageService,
    private dateTimeService: DateTimeService,
    private translateService: TranslateService,
    private routeService: ActivatedRoute
  ) {
    super(injector, MasterDataRow);
    this.startDate = new moment();
    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });

    this.customDataSource = new CustomStore({
      key: 'masterDataListRowId',
      load: (loadOptions) => {

        const observables = [];
        this.masterdataListId = this.routeService.snapshot.params['masterDataListId'];

        observables.push(this.dataService.loadMasterDataListPaged(
          this.masterdataListId, this.id, this.translateService.currentLang, loadOptions));

        observables.push(this.dataService.getMasterDataListForEdit(
          this.masterdataListId, this.id, this.translateService.currentLang));

        if (this.firstLoad) {
          observables.push(this.languageService.getAuctionClusterLanguages(this.id));
        }

        return new Promise((resolve, reject) => {
          forkJoin(observables).subscribe((results: Array<any>) => {
            this.item = <MasterData>results[1];
            this.masterDataListFields = this.item.fields;

            this.masterDataRows = results[0]['data'];
            this.total = this.item.rowCount;
            this.isAddEnabled = this.isNotSystemList() && this.item.enableCreate;

            // Replace dots in column names
            results[0]['data'] = results[0]['data'].map(d => { 
              let obj = { };
              Object.keys(d).forEach(k => {
                let newKey = k.split('.').join('').trim();
                obj[newKey] = d[k];
              });
              return obj;
            });

            if (this.firstLoad) {
              this.languages = <Language[]>results[2];
              this.loading = false;
              this.title.set('MASTER_DATA.TITLE');
              this.title.add(this.item.name);
              this.firstLoad = false;
              this.fetchedAll = true;

              this.generateTable(this.item);
            }

            resolve({
              data: results[0]['data'],
              totalCount: results[0]['totalCount']
            });
          }, error => {
            reject(error);
          });
        });
      }
    });
  }

  reloadGrid() {
    if (!this.dataGridInstance)
      return;

    this.dataGridInstance.getDataSource().reload();
  }

  ngOnInit() {
    this.setTranslations('MASTER_DATA');
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._subscription.unsubscribe();
  }

  getData(sort?: any, filters?: Array<any>) {
  }

  manualRefresh(pageSize?: number) {
    this.reloadGrid();
  }

  refresh(state: any) {
  }

  add() {
    if (this.isAddEnabled) {
      this.detailsComponent.modalTitle = this.translations.ADD_NEW;
      this.detailsComponent.open(this.item, null, this.languages);
    }
  }

  edit = (e: any) => {
    if (!this.item.enableUpdate)
      return;

    const masterDataListRowId = e.row !== undefined ? e.row.data.masterDataListRowId : e.data.masterDataListRowId;
    this.detailsComponent.modalTitle = this.translations.EDIT;

    if (!this.popUpOpened) {
      this.detailsComponent.open(this.item, parseInt(masterDataListRowId), this.languages);
    }
  }

  getCellValue(row: MasterDataRow, field: MasterDataListField) {
    const values = row.values.filter(v => v.masterDataListFieldId === field.masterDataListFieldId);

    const propertyType = field.propertyTypeId;
    if (field.propertyTypeId) {
      switch (propertyType) {
        case ProductPropertyTypeEnum.NUMBER: {
          return values[0] ? values[0].intValue : null;
        }
        case ProductPropertyTypeEnum.DECIMAL: {
          return values[0] ? values[0].decimalValue : null;
        }
        case ProductPropertyTypeEnum.TEXT: {
          if (field.translatable) {
            return values[0].stringValue ? values[0].stringValue : '';
          } else if (values[0] != null && values[0].stringValue != null) {
            var stringValue = values[0].stringValue.replace(/["]/g, '');
            return stringValue;
          }
        }
        case ProductPropertyTypeEnum.DATE: {
          return this.dateTimeService.getDateStringByFormatAnyUtc(values[0].dateTimeValue, field.propertyTypeFormatId);
        }
        case ProductPropertyTypeEnum.IMAGE: {
          return values[0].imageValue;
        }
        case ProductPropertyTypeEnum.BOOLEAN: {
          return values[0].booleanValue ? 'true' : 'false';
        }
        case ProductPropertyTypeEnum.COLOR: {
          return values[0] ? values[0].stringValue : '';
        }
        default: break;
      }
    }
  }

  delete = (e: any) => {
    if (this.isNotSystemList()) {
      this.itemIdToDelete = parseInt(e.row.data.masterDataListRowId);
      this.confirmation.opened = true;
    }
  }

  deleteSelected() {
    this.spinner.show();
    this.dataService.deleteRow(this.id, this.itemIdToDelete)
      .subscribe((rows: Array<MasterDataRow>) => {
        this.reloadGrid();
        this.spinner.hide();
      },
        error => {
          this.errorService.show(this.errorService.translations.DELETE_ERROR_MESSAGE);
          this.spinner.hide();
        });

  }

  initColumn = (e: any) => {
    this.dataGridInstance = e.component;
  }

  generateTable = (masterDataList: any) => {
    this.gridColumns = [];
    let gridCurrentColums = this.dataGridInstance.getVisibleColumns();
    masterDataList.fields.forEach((field: any, i: number) => {
      let columnName = field.name;
      // Replace dots in column names
      columnName = columnName.split('.').join('').trim();

      if (this.gridColumns.includes(columnName)) {
        let j = 0;
        do {
          columnName = field.name + j;
          j++;
        } while (this.gridColumns.includes(columnName));
      }
      this.gridColumns.push({ columnName, field });
      const isColumn = gridCurrentColums.find(c => c.dataField === columnName);
      if (!isColumn) {
        const masterDataListField = field as MasterDataListField;
        const isImage = (masterDataListField.propertyTypeId === ProductPropertyTypeEnum.URL &&
          masterDataListField.propertyTypeFormatId === UrlTypeEnum.IMAGE_URL);

        this.dataGridInstance.addColumn({
          dataField: columnName,
          caption: field.name,
          cellTemplate: isImage ? 'imageCellTemplate' : '',
          visibleIndex: i,
          allowEditing: false,
          dataType: this.getDataType(field.propertyTypeId, field.propertyTypeFormatId),
          encodeHtml: false,
          editorOptions: {
          showClearButton: true
          }
        });
      }
    });

    this.gridColumns.push({ columnName: 'id', row: {} });
    this.dataGridInstance.addColumn({
      caption: 'id',
      visible: false,
      allowEditing: false
    });
  }

  showLargerImage(imgSrc: string) {
    this.spinner.show();
    this.popUpOpened = true;
    this.largeImageSrc = imgSrc;

    if (this.imageLoaded) {
      this.spinner.hide();
      this.popUpShowed = true;
    }
  }

  openPopUp() {
    this.spinner.hide();
    this.popUpShowed = true;
    this.imageLoaded = true;
  }

  closePopUp() {
    this.popUpOpened = false;
    this.popUpShowed = false;
  }

  isEditAllowed = () => {
    return (this.item && this.item.enableUpdate);
  }

  isDeleteAllowed = () => {
    return this.isNotSystemList() && this.item.enableDelete;
  }

  isNotSystemList = () => {
    return (this.item && (this.item.systemListType === 0 ||  this.item.systemListType === 8/*CoinCodes*/
    ||  this.item.systemListType === 14/*MinMaxAmount*/));
  }
}
