<div class="form-group minmaxamount-editor-component">
  <dx-data-grid #minMaxAmountgrid id="minMaxAmoutGrid"
                [dataSource]="minMaxAmountRanges"
                [showBorders]="true"
                [columnAutoWidth]="true"
                [rowAlternationEnabled]="true"
                (onRowInserted)="onDataChanged($event)"
                (onRowUpdated)="onDataChanged($event)"
                (onRowRemoved)="onDataChanged($event)">
    <dxo-editing [allowUpdating]="true" [allowAdding]="true" [allowDeleting]="true"></dxo-editing>
    <dxo-state-storing [enabled]="true" type="localStorage" storageKey="storage_minMaxAmounts"></dxo-state-storing>
    
    <dxi-column dataField="bottomPrice" [sortIndex]="0" sortOrder="desc" caption="{{ 'CLOCK.BOTTOMPRICE' | translate }}"></dxi-column>
    <dxi-column dataField="minAmount" caption="{{ 'CLOCK.MIN_AMOUNT' | translate }}"></dxi-column>
    <dxi-column dataField="maxAmount" caption="{{ 'CLOCK.MAX_AMOUNT' | translate }}"></dxi-column>
    <dxi-column type="buttons" [width]="80">
      <dxi-button hint="{{ 'EDIT' | translate }}" name="edit" icon="edit"></dxi-button>
      <dxi-button hint="{{ 'DELETE' | translate }}" name="delete" icon="trash"></dxi-button>
    </dxi-column>
  </dx-data-grid>
 </div>
